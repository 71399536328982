import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import axios from "axios";
import Fixture from './Fixture';
import NGFixture from './NGFixture';

//import './App.scss';
import './App.css';
import Moment from 'react-moment';
import 'moment-timezone';
// import '@fortawesome/fontawesome-free/css/all.min.css'


axios.defaults.baseURL = process.env.REACT_APP_BASEAPIURL;
// //axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// //axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
//axios.defaults.crossDomain = true;

class App extends Component {
  // client = axios.create({
  //   baseURL: process.env.REACT_APP_BASEAPIURL
  //   // axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  //   // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  // });

  state = {
    // dateSearchFrom: new Date(),
    // dateSearchTo: new Date(),

    leagueFixtures: [],
    ngLeagueFixtures: [],
    ftnLeagueFixtures: [],
    activeLeagueId: null,
  }

  // constructor(props) {
  //   super(props)

  //   // this.handleSelectSportsEvent = this.handleSelectSportsEvent.bind(this)
  //   // this.handleClearSportsEvent = this.handleClearSportsEvent.bind(this)
  // }










  copyLeagueFixturePlayersToClipboard(leagueFixture) {
    
    // determine which fixtures to include
    let fixtureIds = []
    for (let i=0;i<leagueFixture.fixtures.length;i++) {
      fixtureIds.push(leagueFixture.fixtures[i].id);
    }

    let selectedFixtures = [];
    for (let i=0;i<fixtureIds.length;i++) {
      let fixtureId = fixtureIds[i];
      let el = document.querySelectorAll('#chk-fix-' + fixtureId);
      if (el.length == 0) {
        continue;
      }

      if (!el[0].checked) {
        continue;
      }
      
      selectedFixtures.push(fixtureId);
    }

    //alert(selectedFixtures);

    let tableBodys = document.querySelectorAll('#ln-'+leagueFixture.league.id + ' tbody');

    let tableForClipboard = [];

    tableBodys.forEach(tbody => {
    for (let i=0;i<selectedFixtures.length;i++) {
      if (!tbody.classList.contains('fid-'+selectedFixtures[i])) {
        continue;
      }

      let tableRows = tbody.querySelectorAll('tr');
  
      
  
      let includeFixture = false;
  
      tableRows.forEach(row => {
        
        // if (row.className == 'fixture-body')
        // {
        //   console.log('x+'+row.tag)
        //   if (selectedFixtures.includes(parseInt(row.tag)))
        //   {
  
        //     includeFixture = true;
        //   }
        //   else
        //   {
        //     includeFixture = false;
        //   }
        // }
  
        let skipRow = false;
  
        // if (!includeFixture)
        // {
        //   skipRow = true;
        // }
  
        let clipRow = [];
  
        let cells = row.querySelectorAll('th, td');
  
        
        cells.forEach(cell => {
          let cellText = cell.innerText;
          // if (cellText === 'MKT Sup' || cellText === 'MKT TG')												
          // {
          //   skipRow = true; // note, it'll still loop through rest of row, but skpRow won't be reset
          // }
  
          if (cell.className != "dontcopy") {
            clipRow.push(cellText);
          }
          else
          {
            // no cell will be pushed
          }
          
        });
  
        if (!skipRow) {
          tableForClipboard.push(clipRow);
        }
      });
    


    }
    });

    
    this.copy2DToClipboard(tableForClipboard);
  }

  copy2DToClipboard(array) {
    var csv = '', row, cell;
    for (row = 0; row < array.length; row++) {
      for (cell = 0; cell < array[row].length; cell++) {
        csv += (array[row][cell]+'').replace(/[\n\t]+/g, ' ');
        if (cell+1 < array[row].length) csv += '\t';
      }
      if (row+1 < array.length) csv += '\n';
    }

    // for debug as clipboard did not update for user W
    //setDebugClipboardOutput: array;

    this.copyTextToClipboard(csv);
  }
  
  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

























  
  getFixtureTitle(fixture) {
    if (fixture.participants[0].name === "Everton")
    {
      //console.log(fixture);
    }
    
    //console.log(fixture.startTime + " " + fixture.participants[0].name + " v " + fixture.participants[1].name + " (" + fixture.id + ")")
    return fixture.participants[0].name + " v " + fixture.participants[1].name + " (" + fixture.id + ")";
  }

  getNGFixtureTitle(fixture) {
    // if (fixture.team1.name === "Everton")
    // {
    //   //console.log(fixture);
    // }
    //console.log(fixture)
    //console.log(fixture.startTime + " " + fixture.participants[0].name + " v " + fixture.participants[1].name + " (" + fixture.id + ")")
    return fixture.team1.name + " v " + fixture.team2.name + " (" + fixture.id + ")";
  }


  setStateFilter(param, value)
  {
    // alert(param + " " + value)
    this.state[param] = value;
  }

  componentDidMount() {
    
    // const arr = [
    //   ["Mike", "Cane", 23],
    //   ["Jeff", "Meyers", 46],
    //   ["Thomas", "Bush", 67]
    // ];
    // this.copy2DToClipboard(arr);

    let query = new URLSearchParams(window.location.search);
    
    let filterTeams = query.get("teams");
    let filterDate = query.get("date") //new Date(date)
    let filterComp = query.get("comp");

    this.setStateFilter("filterTeams", filterTeams);
    this.setStateFilter("filterDate", filterDate);
    this.setStateFilter("filterComp", filterComp);
    
    // if (teams)
    // {
    //   alert('s')
    // }
    // else
    // {
      
      this.refreshNGResults();
      // this.refreshPinnyResults();
      this.refreshFTNResults();

    // }
  }

  refreshNGResults() {
    axios.get('/api/ng/fixtures?filterTeams='+encodeURIComponent(this.state.filterTeams)+'&filterDate='+encodeURIComponent((this.state.filterDate ? this.getJsDateFromExcel(this.state.filterDate) : ""))+'&filterComp='+encodeURIComponent(this.state.filterComp))
    .then(res => {
        //console.log(res);
        this.setState({ ngLeagueFixtures: res.data, activeLeagueId: res.data[0].league._id});

      }
    )
    .catch(err => console.log(err));
  }


  refreshPinnyResults() {
    console.log(this.state)
    axios.get('/api/pin/fixtures?filterTeams='+encodeURIComponent(this.state.filterTeams)+'&filterDate='+encodeURIComponent((this.state.filterDate ? this.getJsDateFromExcel(this.state.filterDate) : ""))+'&filterComp='+encodeURIComponent(this.state.filterComp))
    .then(res => {
        //console.log(res);
        this.setState({ leagueFixtures: res.data });
      }
    )
    .catch(err => console.log(err));
  }

  refreshFTNResults() {
    console.log(this.state)
    axios.get('/api/ftn/fixtures?filterTeams='+encodeURIComponent(this.state.filterTeams)+'&filterDate='+encodeURIComponent((this.state.filterDate ? this.getJsDateFromExcel(this.state.filterDate) : ""))+'&filterComp='+encodeURIComponent(this.state.filterComp))
    .then(res => {
        //console.log(res);

        // add isChecked to each fixture
        res.data.forEach(leagueFixture => {
          leagueFixture.fixtures.forEach(fixture => {
            fixture.isChecked = true;
          });
        });

        console.log(res.data)

        this.setState({ ftnLeagueFixtures: res.data });
      }
    )
    .catch(err => console.log(err));
  }


  // getFixtures = async (filterTeams, filterDate, filterComp) => {
  //   console.log('getFixtures');

  //   const response = await fetch('/api/pin/fixtures?filterTeams='+encodeURIComponent(filterTeams)+'&filterDate='+encodeURIComponent(filterDate)+'&filterComp='+encodeURIComponent(filterComp));
  //   const body = await response.json();

  //   //console.log(body)

  //   if (response.status !== 200) {
  //     throw Error(body.message) 
  //   }
  //   return body; 
  // }


//   getNGFixtures = async (filterTeams, filterDate, filterComp) => {
//     console.log('getNGFixtures');
// // alert('/api/ng/fixtures?filterTeams='+encodeURIComponent(filterTeams)+'&filterDate='+encodeURIComponent(filterDate)+'&filterComp='+encodeURIComponent(filterComp))
//     const response = await fetch('/api/ng/fixtures?filterTeams='+encodeURIComponent(filterTeams)+'&filterDate='+encodeURIComponent(filterDate)+'&filterComp='+encodeURIComponent(filterComp));
    
//     const body = await response.json();

//     //console.log(body)

//     if (response.status !== 200) {
//       throw Error(body.message) 
//     }
//     return body; 
//   }

//   getFTNFixtures = async (filterTeams, filterDate, filterComp) => {
//     console.log('getFTNFixtures');
// // alert('/api/ng/fixtures?filterTeams='+encodeURIComponent(filterTeams)+'&filterDate='+encodeURIComponent(filterDate)+'&filterComp='+encodeURIComponent(filterComp))
//     const response = await fetch('/api/ftn/fixtures?filterTeams='+encodeURIComponent(filterTeams)+'&filterDate='+encodeURIComponent(filterDate)+'&filterComp='+encodeURIComponent(filterComp));
//     const body = await response.json();

//     //console.log(body)

//     if (response.status !== 200) {
//       throw Error(body.message) 
//     }
//     return body; 
//   }

  

  // useQuery() {
  //   return new URLSearchParams(useLocation().search);
  // }  
  
  getJsDateFromExcel(excelDate) {
    return new Date((excelDate - (25567 + 1))*86400*1000);
  }

  // getParam(param) {
  //   // TODO: try useParams part of the router i.e. let { topicId } = useParams(); 
  //   const queryParams = new URLSearchParams(window.location.search);  
  //   return queryParams.get(param);
  // }

  removeFilter(param) {
    this.setStateFilter(param, "");

    this.refreshPinnyResults();
  }

  removeNGFilter(param) {
    this.setStateFilter(param, "");

    this.refreshPinnyResults();
  }


  removeFTNFilter(param) {
    this.setStateFilter(param, "");

    this.refreshFTNResults();
  }

  removeFTNFilters() {
    this.setStateFilter('filterComp', "");
    this.setStateFilter('filterDate', "");
    this.setStateFilter('filterTeams', "");
    
    this.refreshFTNResults();
  }


  removeNGFilters() {
    this.setStateFilter('filterComp', "");
    this.setStateFilter('filterDate', "");
    this.setStateFilter('filterTeams', "");
    
    this.refreshNGResults();
  }

  removeFilters() {
    this.setStateFilter('filterComp', "");
    this.setStateFilter('filterDate', "");
    this.setStateFilter('filterTeams', "");
    
    this.refreshPinnyResults();
  }

  getLaterDate(date1, date2) {
    if (date1 > date2) {
      return date1;
    }
    return date2;
  }

  render() {
    return (
    
    <div className="App">
      <Router>
        <nav className="navbar nav-main" role="navigation" aria-label="main navigation">
          
          <NavLink className="navbar-item" exact to="/" onClick={() => this.removeFilters()}>Home</NavLink>
          <NavLink className="navbar-item" to="/ng" onClick={() => this.removeNGFilters()}>NG Fixtures</NavLink>
          <NavLink className="navbar-item" to="/fixtures" onClick={() => this.removeFilters()}>Pinny Fixtures</NavLink>
          <NavLink className="navbar-item" to="/ftn" onClick={() => this.removeFTNFilters()}>FTN Fixtures</NavLink>
        
        </nav>

        <Switch>
          {/* <Route path="/fixtures/search">
            
            ?teams=Leicester%20City%20v%20Southampton&date=18/05/2021&comp=FA%20CUP
          </Route> */}
          <Route path="/fixtures/:leagueName/:fixtureId" render={(props) => 
            <Fixture />
          } />
          <Route path="/ng/:leagueName/:fixtureId" render={(props) => 
            <NGFixture />
          } />
          <Route path="/fixtures">
            {/* <Link
              to={{
                pathname: "/fixtures/search",
                search: "?" + this.removeFilter('comp'),
              }}
            >{this.getParam('comp')}</Link>
            <Link
              to={{
                pathname: "/fixtures/search",
                search: "?" + this.removeFilter('date'),
              }}
            >{this.getParam('date')}</Link>
            <Link
              to={{
                pathname: "/fixtures/search",
                search: "?" + this.removeFilter('teams'),
              }}
            >{this.getParam('teams')}</Link>
             */}

{ (this.state.filterComp || this.state.filterDate || this.state.filterTeams ) && 
(<div className="filter">
  <span className="filter-title">Filter</span>
  {(this.state.filterComp === null || !this.state.filterComp) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeFilter('filterComp')}>{this.state.filterComp}</span>)}
  {(this.state.filterDate === null || !this.state.filterDate) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeFilter('filterDate')}><Moment format="ddd DD-MM-yyyy" date={this.getJsDateFromExcel(this.state.filterDate)} /></span>)}
  {(this.state.filterTeams === null || !this.state.filterTeams) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeFilter('filterTeams')}>{this.state.filterTeams}</span>)}
  
</div>)
}

            {/* <header className="App-header">
              Price Playground
            </header> */}
            <div>
            {/* {this.state.selectedFixture === null && (
              <div>
                <div>Filter</div>
                <div>Date From: <input type="text" id="dateSearchFrom" value={this.state.dateSearchFrom}></input></div>
                <div>To: <input type="text" id="dateSearchTo" value={this.state.dateSearchTo}></input></div>
                <div></div>
              </div>
            )} */}
            {this.state.leagueFixtures.map(leagueFixture => 
              <div key={leagueFixture.league._id} className="league-holder">
                <div className="league-title">{leagueFixture.league.name}</div>

                {leagueFixture.fixtures.map(fixture => 
                  <div key={fixture.id}>
                    <Link to={'/fixtures/'+leagueFixture.league.name+'/'+fixture.id}>
                      <Moment format="DD-MM HH:mm" date={fixture.startTime} /> {this.getFixtureTitle(fixture)}
                    </Link>
                  </div>
                )}
              </div>
              )}
            </div>
          </Route>
          <Route path="/ng">

            { (this.state.filterComp || this.state.filterDate || this.state.filterTeams ) && 
              (<div className="filter">
                <span className="filter-title">Filter</span>
                {(this.state.filterComp === null || !this.state.filterComp) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeNGFilter('filterComp')}>{this.state.filterComp}</span>)}
                {(this.state.filterDate === null || !this.state.filterDate) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeNGFilter('filterDate')}><Moment format="ddd DD-MM-yyyy" date={this.getJsDateFromExcel(this.state.filterDate)} /></span>)}
                {(this.state.filterTeams === null || !this.state.filterTeams) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeNGFilter('filterTeams')}>{this.state.filterTeams}</span>)}
              </div>)
            }

            <div className="tabs is-boxed">
              <ul>
                {this.state.ngLeagueFixtures.map(leagueFixture => 
                <li key={leagueFixture.league._id} className={this.state.activeLeagueId == leagueFixture.league._id ? 'is-active' : ''} onClick={() => this.setState({activeLeagueId: leagueFixture.league._id})}>
                  <a>
                    {/* <span class="icon is-small"><i class="fas fa-eye" aria-hidden="true"></i></span> */}
                    <span>{leagueFixture.league.shortName}</span>
                  </a>
                </li>
                )}
              </ul>
            </div>

            <div>
              {this.state.ngLeagueFixtures.map(leagueFixture => 
                <div key={leagueFixture.league.name} className={'league-holder '+ (this.state.activeLeagueId == leagueFixture.league._id ? '' : 'is-hidden')}>
                  <h2 className="subtitle is-4">{leagueFixture.league.name}</h2>

                  <table className="table is-striped is-fullwidth">
                    <thead>
                      <tr>
                        <th>Round</th>
                        <th>KO</th>
                        <th>Home</th>
                        <th>&nbsp;</th>
                        <th>Away</th>
                        <th>Id</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leagueFixture.fixtures.map(fixture => 
                        <tr key={fixture.id}>
                          <td>
                            {fixture.round != null ? fixture.round.name : fixture.roundNum}
                          </td>
                          <td><Moment format="DD-MM HH:mm" date={fixture.startTime} /></td>
                          <td>{fixture.team1.name}</td>
                          <td>
                            <div>{fixture.scoreFT.replace('推迟|推遲|','')} <span className={(fixture.scoreHT != '' ? '' : 'is-hidden')}>({fixture.scoreHT})</span></div>
                          </td>
                          <td>{fixture.team2.name}</td>
                          <td>{fixture.id}</td>
                          <td>
                            <Link to={'/ng/'+leagueFixture.league.name+'/'+fixture.id}>
                              View  
                            </Link>
                          </td>
                          
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Route>
          <Route path="/ftn">

            { (this.state.filterComp || this.state.filterDate || this.state.filterTeams ) && 
              (<div className="filter">
                <span className="filter-title">Filter</span>
                {(this.state.filterComp === null || !this.state.filterComp) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeNGFilter('filterComp')}>{this.state.filterComp}</span>)}
                {(this.state.filterDate === null || !this.state.filterDate) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeNGFilter('filterDate')}><Moment format="ddd DD-MM-yyyy" date={this.getJsDateFromExcel(this.state.filterDate)} /></span>)}
                {(this.state.filterTeams === null || !this.state.filterTeams) ? (<span></span>) : (<span className="filter-link" onClick={() => this.removeNGFilter('filterTeams')}>{this.state.filterTeams}</span>)}
              </div>)
            }

            <div>
            {this.state.ftnLeagueFixtures.map(leagueFixture => 
              <div key={leagueFixture.league.name} className="league-holder">
                <div className="league-title">{leagueFixture.league.name}</div>

                <div>
                  {leagueFixture.fixtures.map(fixture => 
                    <div key={fixture.id}>
                      {/* <input type="checkbox" value={fixture.id} defaultChecked name={'chk-fix-'+fixture.id} id={'chk-fix-'+fixture.id} /> <label for={'chk-fix-'+fixture.id}><Moment format="DD-MM HH:mm" date={fixture.startTime} /> {this.getNGFixtureTitle(fixture)}</label> */}
                      <input type="checkbox" value={fixture.id} name={'chk-fix-'+fixture.id} id={'chk-fix-'+fixture.id} /> <label htmlFor={'chk-fix-'+fixture.id}><Moment format="DD-MM HH:mm" date={fixture.startTime} /> {this.getNGFixtureTitle(fixture)}</label>
                      { (fixture.checkedat) && (<span className="pl-3">checked <Moment fromNow date={fixture.checkedat} /></span>)}
                      <a target="_blank" className="external-reportlink" href={"https://www.footballteamnews.com/client/football-match-preview?match_id="+fixture.id+"&tour_id="+leagueFixture.league.id}>view report</a>
                    </div>
                  )}
                </div>

                <button className="button is-link" onClick={() => this.copyLeagueFixturePlayersToClipboard(leagueFixture)}> 
                  <span className="icon">
                    <i className="fas fa-clipboard-list"></i>
                  </span>
                  <span>Copy selected {leagueFixture.league.name} fixtures</span>
                </button>

                <table className='table players' id={'ln-'+leagueFixture.league.id}>
                  {leagueFixture.fixtures.map(fixture => 
                      <tbody className={'fixture-body  fid-'+fixture.id} key={fixture.id}>
                        {/* hidden */}
                        <tr>
                          <td className='topSpace'>
                            <div>Fixture</div>
                            {/* <div><Moment fromNow date={this.getLaterDate(fixture.previewhomemodifieddate, fixture.previewawaymodifieddate)} /></div> */}
                            <div>last home update: <Moment format="DD-MM HH:mm" date={fixture.previewhomemodifieddate} /> (<Moment fromNow date={fixture.previewhomemodifieddate} />)</div>
                            <div>last away update: <Moment format="DD-MM HH:mm" date={fixture.previewawaymodifieddate} /> (<Moment fromNow date={fixture.previewawaymodifieddate} />)</div>
                          </td>
                          <td colSpan="3" className='topspace'>
                            {/* <Link to={'/ng/'+leagueFixture.league.name+'/'+fixture.id}> */}
                              <Moment format="DD-MM HH:mm" date={fixture.startTime} /> {this.getNGFixtureTitle(fixture)}
                              
                              { (fixture.isneutral) && 
                                (<b>Neutral</b>)
                              }
                              
                            {/* </Link> */}
                          </td>
                          {/* <td className='dontcopy'>
                            <a target="_blank" className="external-reportlink" href={"https://www.footballteamnews.com/client/football-match-preview?match_id="+fixture.id+"&tour_id="+leagueFixture.league.id}>view report</a>
                          </td> */}
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <h2>{fixture.team1.name}</h2>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <h3>Suspended</h3>
                          </td>
                        </tr>
                        {fixture.homeplayerssuspended && fixture.homeplayerssuspended.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}
                    
                        <tr>
                          <td colSpan="4">
                            <h3>Unavailable</h3>
                          </td>
                        </tr>
                        {fixture.homeplayersunavailable && fixture.homeplayersunavailable.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan="4">
                            <h3>Doubtful</h3>
                          </td>
                        </tr>
                        {fixture.homeplayersdoubtful && fixture.homeplayersdoubtful.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}
                    
                        <tr>
                          <td colSpan="4">
                            <h3>Rested</h3>
                          </td>
                        </tr>
                        {fixture.homeplayersrested && fixture.homeplayersrested.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan="4">
                            <h2>{fixture.team2.name}</h2>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4">
                            <h3>Suspended</h3>
                          </td>
                        </tr>
                        {fixture.awayplayerssuspended && fixture.awayplayerssuspended.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}
                    
                        <tr>
                          <td colSpan="4">
                            <h3>Unavailable</h3>
                          </td>
                        </tr>
                        {fixture.awayplayersunavailable && fixture.awayplayersunavailable.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}

                        <tr>
                          <td colSpan="4">
                            <h3>Doubtful</h3>
                          </td>
                        </tr>
                        {fixture.awayplayersdoubtful && fixture.awayplayersdoubtful.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}
                    
                        <tr>
                          <td colSpan="4">
                            <h3>Rested</h3>
                          </td>
                        </tr>
                        {fixture.awayplayersrested && fixture.awayplayersrested.map(player => 
                          <tr key={player.name}>
                            <td className="playername">{player.name}</td>
                            <td className="playerrating">{player.rating}</td>
                            <td className="playerposition">{player.position}</td>
                            <td className="playerchance">{player.chance}</td>
                          </tr>
                        )}
                      </tbody>
                  )}
                </table>
              </div>
              )}
            </div>
          </Route>
          <Route path="/">:)</Route>
        </Switch>
      </Router>
    </div>
    );
  }
}

export default App;