import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import Moment from 'react-moment';
import 'moment-timezone';
import {
//  BrowserRouter as Router,
//  Switch,
//  Route,
  Link,
//  NavLink
} from "react-router-dom";
import axios from "axios";

function Fixture(props){
  const { fixtureId, leagueName } = useParams();

  const [selectedFixture, setSelectedFixture] = useState(null);

  const [selectedFixturePrices, setSelectedFixturePrices] = useState([]);
  const [priceHistory, setPriceHistory] = useState([]);
  const [excelTimePoints, setExcelTimePoints] = useState([]);

  const [fixedAH, setFixedAH] = useState(null);
  const [fixedTG, setFixedTG] = useState(null);

  var root = this;

  useEffect(() => {
    doGetFixture();
  }, []) // using [] means this will run exactly once
  
  useEffect(() => {
    doGetFixture();
  }, [fixtureId])

  useEffect(() => {
    if (selectedFixture != null)
    {
      // console.log("selectedFixture")
      // console.log(selectedFixture)
      regenPriceHistory(selectedFixturePrices, fixedAH, fixedTG);
    }
	}, [selectedFixturePrices]); // update if any of these props change

  function getFixtureTitle(fixture) {
    return fixture.participants[0].name + " v " + fixture.participants[1].name; // + " (" + fixture.id + ")";
  }

  function getFixtureLinkToPin(fixture) {
    return "https://www.pinnacle.com/en/soccer/x/"+fixture.participants[0].name.replace(/ /g, "-")+"-vs-"+fixture.participants[1].name.replace(/ /g, "-")+ "/"+fixture.id+"/";
  }

  function IsStartTimeInFuturePlusTwoHours(fixture)
  {
    let compareDate = new Date();
    compareDate.setTime(compareDate.getTime() + (2*60*60*1000));
    return (new Date(fixture.startTime) > compareDate);
  }
  
  function doGetFixture()
  {
    axios.get('/api/pin/fixtures?fixtureId=' + fixtureId)
    .then(res => {
      if (res.data.length === 0 || res.data[0].fixtures.length === 0)
      {
        // fixture not found
        return;
      }

      setFixedAH(null);
      setFixedTG(null);

      setSelectedFixture(res.data[0].fixtures[0]);

      axios.get('/api/pin/prices?matchupId='+encodeURIComponent(fixtureId))
      .then(res => {
        //console.log(res);
        setSelectedFixturePrices(res.data);
      })
      .catch(err => console.log(err));
    })
  .catch(err => console.log(err));
  }

  function regenPriceHistory(fixturePrices, fixedAH, fixedTG)
  {
    setPriceHistory([]);
    let newPH = [];

    let mainLineAHFixturePriceBlocks = [];
    let mainLineTGFixturePriceBlocks = [];
    let uniqueChangedAts = [];
    let currentChangedAt = null;

  // console.log('fixturePrices for lastseen diag')
  // console.log(fixturePrices);
    // convert to our models, and make a note of all the unique changed at dates
    fixturePrices.forEach(fixturePriceBlock => {
      if (currentChangedAt !== fixturePriceBlock.changedAt)
      {
        currentChangedAt = fixturePriceBlock.changedAt;
        uniqueChangedAts.push(currentChangedAt);
      }

      // let fixedAH = this.state.fixedAH;
      // let fixedTG = this.state.fixedTG; // should use 2.75
      let fixedH = null;

      let decimalPriceGroups = [];

      switch (fixturePriceBlock.type)
      {
        case "spread":
          if (fixedAH != null)
          {
            fixedH = fixedAH;
          }
          break;
        case "total":
          if (fixedTG != null)
          {
            fixedH = fixedTG;
          }
          break;
        default:
          console.log("unexpected type: " + fixturePriceBlock.type)
      }

      decimalPriceGroups = convertToDecimalPriceGroups(fixturePriceBlock.priceGroups, fixedH);
      
      // reorder prices (may want to do it differently for TGs)
      decimalPriceGroups.sort((a, b) => {
              
        if (a.pDist > b.pDist) {
          return 1;
        } else if (a.pDist < b.pDist) { 
            return -1;
        }

        // else go to the 2nd item
        if (a.hDist < b.hDist) { 
            return -1;
        } else if (a.hDist > b.hDist) {
            return 1
        } else { // nothing to split them
            return 0;
        }
      });

      if (decimalPriceGroups.length > 0) { // will be 0 if user has chosen a fixed handicap that wasn't found, in which case we ignore/do nothing

        let decimalPriceGroup = decimalPriceGroups[0];
        
        // add changed at property
        decimalPriceGroup.changedAt = currentChangedAt;
        
        // add last seen at property (double check which one we're using here)
        decimalPriceGroup.lastSeenAt = fixturePriceBlock.lastSeenAt;

        switch (fixturePriceBlock.type)
        {
          case "spread":
            mainLineAHFixturePriceBlocks.push(decimalPriceGroup);
            break;
          case "total":
            mainLineTGFixturePriceBlocks.push(decimalPriceGroup);
            break;
          default:
            console.log("unexpected type: " + fixturePriceBlock.type)
        }
      }
    });

  // console.log("mainLineAHFixturePriceBlocks")
  // console.log(mainLineAHFixturePriceBlocks)

    // let currentRow = null;
    let AHcursor = 0;
    let TGcursor = 0;
    let currentAHMain = null;
    let currentTGMain = null;

    // the last objs are used to work out differences
    let lastAHMain = null;
    let lastTGMain = null;

    uniqueChangedAts.forEach(changedAt => {
      
      // seek up our AH models until we get to the most recent one at the time of this changed at
      while (AHcursor < mainLineAHFixturePriceBlocks.length)
      {
        // keep replacing until we find the latest AH up to that time
        //console.log(mainLineAHFixturePriceBlocks[AHcursor].changedAt + " <= " + changedAt)
        
        if (mainLineAHFixturePriceBlocks[AHcursor].changedAt <= changedAt)
        {
          currentAHMain = mainLineAHFixturePriceBlocks[AHcursor];
          // console.log(currentAHMain)

          // move back so that each iteration we only loop from the last spot, not through the whole array from top to bottom
          AHcursor++;
        }
        else
        {
          break;
        }
      }

      // seek up our AH models until we get to the most recent one at the time of this changed at
      while (TGcursor < mainLineTGFixturePriceBlocks.length)
      {
        // keep replacing until we find the latest TG up to that time
        if (mainLineTGFixturePriceBlocks[TGcursor].changedAt <= changedAt)
        {
          currentTGMain = mainLineTGFixturePriceBlocks[TGcursor];
          
          // move back so that each iteration we only loop from the last spot, not through the whole array from top to bottom
          TGcursor++;
        }
        else
        {
          break;
        }
      }
      
      if (currentAHMain != null)
      {
        addChangeDirectionToCurrentLine(currentAHMain, lastAHMain);
      }
      if (currentTGMain != null)
      {
        addChangeDirectionToCurrentLine(currentTGMain, lastTGMain);
      }

      if (currentAHMain != null && currentTGMain != null) { // this condition will hopefully mean that if only one line or the other is foound, we move forward/ignore it. Fix for first prices having only AH and no TG
        newPH.push({
          AH: currentAHMain,
          TG: currentTGMain,
          lastSeenAt: currentAHMain.lastSeenAt, // just using AH as they come in together
          changedAt: changedAt   
        })
  
        lastAHMain = currentAHMain;
        lastTGMain = currentTGMain;
      }
    });
    
    console.log(newPH)
    setPriceHistory(newPH);

    // regen excel
    regenExcelTimePoints(selectedFixture.startTime, newPH);
  }

  function regenExcelTimePoints(kickoff, priceHistory) {
    
    console.log(kickoff)
    kickoff = new Date(kickoff);
    console.log(kickoff)
    
    let dayOP = new Date();

    // get the first data point for AH/TG
    if (priceHistory.length > 0)
    {
      dayOP = new Date(priceHistory[0].changedAt);
    }

    // let day6 = new Date(kickoff.getTime());
    // day6.setHours(day6.getHours() - (24*6));
    // day6.setHours(9); //set to 9am
    // day6.setMinutes(0);

    // let day5 = new Date(kickoff.getTime());
    // day5.setHours(day5.getHours() - (24*5));
    // day5.setHours(9); //set to 9am
    // day5.setMinutes(0);

    let day4 = new Date(kickoff.getTime());
    day4.setHours(day4.getHours() - (24*4));
    day4.setHours(9); //set to 9am
    day4.setMinutes(0);

    let day3 = new Date(kickoff.getTime());
    day3.setHours(day3.getHours() - (24*3));
    day3.setHours(9); //set to 9am
    day3.setMinutes(0);

    let day2 = new Date(kickoff.getTime());
    day2.setHours(day2.getHours() - (24*2));
    day2.setHours(9); //set to 9am
    day2.setMinutes(0);
    
    let day1 = new Date(kickoff.getTime());
    day1.setHours(day1.getHours() - (24*1));
    day1.setHours(9); //set to 9am
    day1.setMinutes(0);

    let am = new Date(kickoff.getTime());
    let amLabel = "9am";

    // hours are 0 - 23
    if (am.getHours() <= 9)
    {
      // if it's very early we simply look at market 9hrs earlier
      ///am.setHours(am.getHours() - 9);

      am.setHours(-10);
      am.setHours(9);
      am.setMinutes(0);

      amLabel = "9am day before";

      // move all other days back by 1 day
      day1.setHours(day1.getHours()-24);
      day2.setHours(day2.getHours()-24);
      day3.setHours(day3.getHours()-24);
      day4.setHours(day4.getHours()-24);
      // day5.setHours(day5.getHours()-24);
      // day6.setHours(day6.getHours()-24);
    }
    else // set to 9am on the day (8)
    {
      am.setHours(9);
    }
    
    let hr1BeforeKickoff = new Date(kickoff.getTime());
    hr1BeforeKickoff.setHours(hr1BeforeKickoff.getHours() - 1);
    
    let alt = new Date(kickoff.getTime());
    alt.setMinutes(alt.getMinutes() + 3);


    let min10AfterKickoff = new Date(kickoff.getTime());
    min10AfterKickoff.setMinutes(min10AfterKickoff.getMinutes() + 10);

    let now = new Date();
    let dateToUseForKO = kickoff;

    // requirmeent for KO to be filled with latest price if we have yet to reach kickoff datetime
    if (now < dateToUseForKO)
    {
      dateToUseForKO = now;
    }

    let excelTimePoints = [
      {label: "OP", stopPoint: dayOP, dontCopy: false},
      // {label: "6", stopPoint: day6, dontCopy: false},
      // {label: "5", stopPoint: day5, dontCopy: false},
      {label: "4", stopPoint: day4, dontCopy: false},
      {label: "3", stopPoint: day3, dontCopy: false},
      {label: "2", stopPoint: day2, dontCopy: false},
      {label: "1", stopPoint: day1, dontCopy: false},
      {label: amLabel, stopPoint: am, dontCopy: false},
      {label: "1hr", stopPoint: hr1BeforeKickoff, dontCopy: false},
      {label: "ko", stopPoint: dateToUseForKO, dontCopy: true},
      {label: "3 min", stopPoint: alt, dontCopy: true},
      {label: "10 min", stopPoint: null, dontCopy: true}, // min10AfterKickoff
    ];

    console.log(excelTimePoints)
    
    // now associate the correct data history point with each timePoint
    excelTimePoints.forEach(excelTimePoint => {
      let currentStopPoint = excelTimePoint.stopPoint;
      let mostRecentValidDataPoint = null;

      // make sure if we're viewing before ko that we don't fill grid with data we don't need
      if (!(new Date(excelTimePoint.stopPoint) > now))
      {
        //console.log("priceHistory: " + priceHistory)
        priceHistory.forEach(priceHistoryItem => {
          // an inefficient approach as it'll finish the loop (really want to "break" but this will have same effect)
          //console.log(priceHistoryItem.changedAt + " <= " + currentStopPoint)
          if (new Date(priceHistoryItem.changedAt) <= currentStopPoint)
          {
            mostRecentValidDataPoint = priceHistoryItem;  
          }
        });
      }

      excelTimePoint.dataPoint = mostRecentValidDataPoint;
    });
    
    console.log(excelTimePoints);

    setExcelTimePoints(excelTimePoints);
  }
  
  function addChangeDirectionToCurrentLine(currentLine, lastLine)
  {
    if (lastLine == null)
    {
      currentLine.handicapDirection = 0;
      currentLine.price1Direction = 0;
      currentLine.price2Direction = 0;
      return;
    }

    if (currentLine.handicap > lastLine.handicap)
    {
      currentLine.handicapDirection = 1;
    }
    else if (currentLine.handicap < lastLine.handicap)
    {
      currentLine.handicapDirection = -1;
    }
    else
    {
      currentLine.handicapDirection = 0;
    }

    if (currentLine.price1 > lastLine.price1)
    {
      currentLine.price1Direction = 1;
    }
    else if (currentLine.price1 < lastLine.price1)
    {
      currentLine.price1Direction = -1;
    }
    else
    {
      currentLine.price1Direction = 0;
    }

    if (currentLine.price2 > lastLine.price2)
    {
      currentLine.price2Direction = 1;
    }
    else if (currentLine.price2 < lastLine.price2)
    {
      currentLine.price2Direction = -1;
    }
    else
    {
      currentLine.price2Direction = 0;
    }
  }

  function toDecimalFromAmerican(americanOdds)
  {
    if (americanOdds == null)
    {
        return americanOdds;
    }

    if (americanOdds > 0)
    {
        return 1 + (americanOdds / 100);
    }
    else
    {
        return 1 - (100 / americanOdds);
    }
  }

  function distanceFromEvens(price1, price2)
  {
    // this.DistanceFromEvens = Math.Round((Math.Abs(1 - Math.Abs(this.Price1[0].SystemPrice))) + (Math.Abs(1 - Math.Abs(this.Price2[0].SystemPrice))), 3);
    return (Math.abs(1 - Math.abs(price1))) + (Math.abs(1 - Math.abs(price2)));
  }

  function handicapDistanceFromZero(handicap)
  {
    return Math.abs(0 - handicap);
  }

  function convertToDecimalPriceGroups(priceGroups, fixedHandicap)
  {
    // input is priceGroups e.g. 
    /*
      [
      {"points":"0.75","prices":[
        {"designation":"home","price":-114,"points":"0.75"},
        {"designation":"away","price":105,"points":"-0.75"}
      ]},
      {"points":"-0.25","prices":
      [{"designation":"home","price":274,"points":"-0.25"},
      {"designation":"away","price":-320,"points":"0.25"}
      ]},
    */

    let decimalPriceGroup = [];

    // convert price groups to a new decimal price group
    priceGroups.filter(pg => (fixedHandicap == null || pg.points == fixedHandicap)).forEach(priceGroup => {
      let price1 = toDecimalFromAmerican(priceGroup.prices[0].price);
      let price2 = toDecimalFromAmerican(priceGroup.prices[1].price);
      let handicap = parseFloat(priceGroup.points);
      //console.log("Z" + handicap)

      decimalPriceGroup.push({
        handicap: handicap,
        price1: price1,
        price2: price2,
        hDist: handicapDistanceFromZero(handicap),
        pDist: distanceFromEvens(price1, price2),
      });
    });

    return decimalPriceGroup;
  }

  function handleHandicapClick(newAH, newTG) {

    let updateRequired = false;

    if (newAH != fixedAH)
    {
      updateRequired = true;

      setFixedAH(newAH);
    }

    if (newTG != fixedTG)
    {
      updateRequired = true;

      setFixedTG(newTG);
    }

    if (updateRequired)
    {
      regenPriceHistory(selectedFixturePrices, newAH, newTG);
    }
  }

  function fixToKOHandicaps(){
    // find ko handicap
    excelTimePoints.filter(e => e.label === "ko").forEach(excelTimePoint => {
      //console.log(excelTimePoint)
      handleHandicapClick(excelTimePoint.dataPoint.AH.handicap, excelTimePoint.dataPoint.TG.handicap)
    });
  }

  function copyMarketOverviewToClipboard() {
    let marketOverviewTableRows = document.querySelectorAll('#marketOverview tr');

    let tableForClipboard = [];

    marketOverviewTableRows.forEach(row => {
      let clipRow = [];

      let cells = row.querySelectorAll('th, td');

      let skipRow = false;
      cells.forEach(cell => {
        let cellText = cell.innerText;
        if (cellText === 'MKT Sup' || cellText === 'MKT TG')												
        {
          skipRow = true; // note, it'll still loop through rest of row, but skpRow won't be reset
        }

        if (cell.className != "dontcopy") {
          clipRow.push(cellText);
        }
        else
        {
          // no cell will be pushed
        }
        
      });

      if (!skipRow) {
        tableForClipboard.push(clipRow);
      }
    });

    

    copy2DToClipboard(tableForClipboard);
  }

  function copy2DToClipboard(array) {
    var csv = '', row, cell;
    for (row = 0; row < array.length; row++) {
      for (cell = 0; cell < array[row].length; cell++) {
        csv += (array[row][cell]+'').replace(/[\n\t]+/g, ' ');
        if (cell+1 < array[row].length) csv += '\t';
      }
      if (row+1 < array.length) csv += '\n';
    }

    // for debug as clipboard did not update for user W
    //setDebugClipboardOutput: array;

    copyTextToClipboard(csv);
  }
  
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  // var oddsLiquidity = 100; //props.oddsLiquidity;

  // const [currentPrice, setCurrentPrice] 	= useState(oddsLiquidity.odds);
  // const [currentPriceClass, setCurrentPriceClass] 	= useState('');

  // useEffect(() => {
		
	// 	if (oddsLiquidity.odds === currentPrice){
  //    return;
  //   }

  //   if (currentPrice < oddsLiquidity.odds)
  //   {
  //     setCurrentPriceClass("price-up");
  //   }
  //   else
  //   {
  //     setCurrentPriceClass("price-down");
  //   }
    
  //   setCurrentPrice(oddsLiquidity.odds);

	// }, [oddsLiquidity, currentPrice]); // update if either of these props change

  // return (
  //   <div className="backPriceBox">
  //     <div className={'price ' + currentPriceClass}>{parseFloat(oddsLiquidity.odds).toFixed(2)}</div>
  //     <div className="liquidty">&pound;{parseFloat(oddsLiquidity.liquidity).toFixed(2)}</div>
  //   </div>
  // )

  return (
          <div>
            {selectedFixture !== null && (
              <div>
                <nav className="panel smaller is-pulled-right">
                  <p className="panel-heading">
                    Fixed HCs
                  </p>
                  {/* <div class="panel-block">
                    <p class="control has-icons-left">
                      <input class="input" type="text" placeholder="Search" />
                      <span class="icon is-left">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </span>
                    </p>
                  </div> */}
                
                  <div className="panel-block">
                    <div className="columns">
                      <div className="column">
                        <label>AH</label>
                      </div>
                      <div className="column">
                        {fixedAH === null && (<span>N/A</span>)} <span className="handicap active" onClick={() => handleHandicapClick(null, fixedTG)}>{fixedAH != null ? fixedAH.toFixed(2) : ''}</span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-block">
                    <div className="columns">
                      <div className="column">
                        <label>TG</label>
                      </div>
                      <div className="column">
                      {fixedTG === null && (<span>N/A</span>)} <span className="handicap active" onClick={() => handleHandicapClick(fixedAH, null)}>{fixedTG != null ? fixedTG.toFixed(2) : ''}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div class="panel-block">
                    <button class="button is-link is-outlined">
                      KO
                    </button>
                    <button class="button is-link is-outlined">
                      Clear
                    </button>
                  </div> */}
                </nav>
                
                <div className="header">
                  <Link to={'/fixtures'}>{leagueName}</Link>
                  <h1 className="title">{getFixtureTitle(selectedFixture)}</h1>
                  {/* <Moment date={selectedFixture.startTime} format="dd hh:mm:ss" fromNowDuring /> @  */}
                  <h2 className="subtitle"><Moment format="ddd DD-MM HH:mm" date={selectedFixture.startTime} /> - <span>Pin Id:&nbsp;</span> 
                    {IsStartTimeInFuturePlusTwoHours(selectedFixture) && (
                      <a target="_blank" href={getFixtureLinkToPin(selectedFixture)}>{selectedFixture.id}</a>
                    )}
                    {!IsStartTimeInFuturePlusTwoHours(selectedFixture) && (
                      <span>{selectedFixture.id}</span>
                    )}
                    
                  </h2>
                </div>
                
                <div>
                  <div><h1>Market Overview</h1></div>

                  
                  <div className="buttons are-small">
                      <button className="button is-link" onClick={() => fixToKOHandicaps()} >
                        <span className="icon">
                          <i className="fas fa-bullseye"></i>
                        </span>
                        <span>Fix to KO Handicaps</span>
                      </button>
                    
                      <button className="button is-link" onClick={() => copyMarketOverviewToClipboard()} >
                        <span className="icon">
                          <i className="fas fa-clipboard"></i>
                        </span>
                        <span>Copy</span>
                      </button>
                  </div>
                  <div>
                  <table className="table is-hoverable market-overview" id="marketOverview">
                      <thead>
{/*                         
                        <tr >
                          <th>cut off at</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{(
                              <div><Moment format="Do HH:mm:ss" date={excelTimePoint.stopPoint} /></div>
                            )}
                            </td>    
                          )}
                        </tr> */}
                        
                        <tr>
                          <th>last seen at</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && (
                              <div><Moment format="HH:mm:ss" date={excelTimePoint.dataPoint.lastSeenAt} /></div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th>since</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && (
                              <div><Moment format="HH:mm:ss" date={excelTimePoint.dataPoint.changedAt} /></div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th></th>
                          {excelTimePoints.map(excelTimePoint => 
                            <th key={excelTimePoint.label} className={excelTimePoint.dontCopy?'dontcopy':''} tooltip={excelTimePoint.stopPoint}>{excelTimePoint.label}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>HC</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.AH !== null && (
                              <div>
                                {(fixedAH === excelTimePoint.dataPoint.AH.handicap) ? (<div className="handicap active" onClick={() => handleHandicapClick(null, fixedTG)}>{excelTimePoint.dataPoint.AH.handicap.toFixed(2)}</div>) : (<div className="handicap" onClick={() => handleHandicapClick(excelTimePoint.dataPoint.AH.handicap, fixedTG)}>{excelTimePoint.dataPoint.AH.handicap.toFixed(2)}</div>)} 
                              </div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th>home</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.AH !== null && (
                              <div>{excelTimePoint.dataPoint.AH.price1.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>away</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.AH !== null && (
                              <div>{excelTimePoint.dataPoint.AH.price2.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>TG</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.TG !== null && (
                              <div>
                              {(fixedTG === excelTimePoint.dataPoint.TG.handicap) ? (<div className="handicap active" onClick={() => handleHandicapClick(fixedAH, null)}>{excelTimePoint.dataPoint.TG.handicap.toFixed(2)}</div>) : (<div className="handicap" onClick={() => handleHandicapClick(fixedAH, excelTimePoint.dataPoint.TG.handicap)}>{excelTimePoint.dataPoint.TG.handicap.toFixed(2)}</div>)} 
                              </div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th>O</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.TG !== null && (
                              <div>{excelTimePoint.dataPoint.TG.price1.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>U</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.TG !== null && (
                              <div>{excelTimePoint.dataPoint.TG.price2.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>MKT Sup</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td></td>    
                          )}
                        </tr>
                        <tr>
                          <th>MKT TG</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td></td>    
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div><h1>Price History</h1></div>
                  <div>
                    <table className="table is-hoverable is-striped market-history">
                      <thead>
                        <tr>
                          {/* <th>lastSeenAt</th> */}
                          <th colSpan="2">changed</th>
                          <th>AH</th>
                          <th>Home</th>
                          <th>Away</th>
                          <th>TG</th>
                          <th>Overs</th>
                          <th>Unders</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceHistory.map(phRow => 
                          <tr>
                            {/* <td><Moment format="DD-MM HH:mm:ss" date={phRow.lastSeenAt} /></td> */}
                            <td><Moment format="ddd Do" date={phRow.changedAt} /></td>
                            <td><Moment format="HH:mm:ss" date={phRow.changedAt} /></td>
                            
                            <td>{phRow.AH !== null && (
                              

                              <div>
                                {(fixedAH === phRow.AH.handicap) ? (
                                    <div className={"handicap active change-direction-"+phRow.AH.handicapDirection} onClick={() => handleHandicapClick(null, fixedTG)}>{phRow.AH.handicap.toFixed(2)}</div>
                                  ) : (
                                    <div className={"handicap change-direction-"+phRow.AH.handicapDirection} onClick={() => handleHandicapClick(phRow.AH.handicap, fixedTG)}>{phRow.AH.handicap.toFixed(2)}</div>
                                  )} 
                              </div>


                            )}</td>
                            <td>{phRow.AH !== null && (
                              <div className={"price change-direction-"+phRow.AH.price1Direction}>{phRow.AH.price1.toFixed(2)}</div>
                            )}</td>
                            <td>{phRow.AH !== null && (
                              <div className={"price change-direction-"+phRow.AH.price2Direction}>{phRow.AH.price2.toFixed(2)}</div>
                            )}</td>
                            
                            <td>{phRow.TG !== null && (
                     
                              <div>
                                {(fixedTG === phRow.TG.handicap) ? (
                                  <div className={"handicap active change-direction-"+phRow.TG.handicapDirection} onClick={() => handleHandicapClick(fixedAH, null)}>{phRow.TG.handicap.toFixed(2)}</div>
                                ) : (
                                  <div className={"handicap change-direction-"+phRow.TG.handicapDirection} onClick={() => handleHandicapClick(fixedAH, phRow.TG.handicap)}>{phRow.TG.handicap.toFixed(2)}</div>
                                )} 
                              </div>

                            )}</td>
                            <td>{phRow.TG !== null && (
                              <div className={"price change-direction-"+phRow.TG.price1Direction}>{phRow.TG.price1.toFixed(2)}</div>
                            )}</td>
                            <td>{phRow.TG !== null && (
                              <div className={"price change-direction-"+phRow.TG.price2Direction}>{phRow.TG.price2.toFixed(2)}</div>
                            )}</td>

                            {/* <td>{phRow.TG.handicap.toFixed(2)}</td>
                            <td>{phRow.TG.price1.toFixed(2)}</td>
                            <td>{phRow.TG.price2.toFixed(2)}</td> */}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
    
  )
}
export default Fixture;