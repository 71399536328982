import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import Moment from 'react-moment';
import 'moment-timezone';
import CollapsiblePanel from './CollapsiblePanel'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import axios from "axios";


function FixtureList(props) {

  const [fixtures, setFixtures] = useState([]);

  useEffect(() => {
    loadFixtures();
  }, [props.round, props.selectedLeague])

  function loadFixtures() {
    if (!(props.round && props.selectedLeague))
    {
      console.log('did not load fixtures because round and/or selectedLeague is blank')
      return;
    }

    axios.get('/api/ng/fixtures?filterRoundNumber='+props.round+'&filterLeagueId='+props.selectedLeague._id)
    .then(res => {
      if (res.data.length === 0) {
        // no fixtures
        setFixtures([]);
        return;
      }
      else {
        setFixtures(res.data[0].fixtures);
      }
    }).catch(err => {
      console.log(err)
    });
  }



  return (
    <div>
      {/* <div>
        <h2 className="subtitle">Fixtures</h2>
      </div> */}
      {fixtures != null && (
        <CollapsiblePanel title={(
          <div>{props.selectedLeague.name} - Round {props.round}</div>
        )}
        content={(
          <div className="control is-size-7">
            {fixtures.map((fixture) => (
              <div key={fixture.id} className={'' + (props.activeFixtureId == fixture.id ? 'is-active has-background-link has-text-link-light' : '')}>
                <Link to={'/ng/'+props.selectedLeague.name+'/'+fixture.id}>View</Link> &nbsp; <Moment format="DD-MM HH:mm" date={fixture.startTime} /> &nbsp; <span>{fixture.team1.name} v {fixture.team2.name}</span>
              </div>
            ))}
          </div> 
        )} />

        )}
    </div>
  )
}
export default FixtureList;