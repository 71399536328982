import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';

function CollapsiblePanel(props) {
  const [isOpen, setIsOpen] = useState(true); // use (props.isOpen to set default state

  useEffect(() => {
  }, [props.title, props.content])

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="card mb-4">
      <header className="card-header is-clickable" onClick={()=> toggleIsOpen()}>
        <p className="card-header-title">
          {props.title}
        </p>
        <button className="button card-header-icon" aria-label="more options">
          {/* <span className="icon">
            <i className={isOpen ? 'fas fa-angle-up' : 'fas fa-angle-down'} aria-hidden="true"></i>
          </span> */}
          Toggle
        </button>
      </header>
      {isOpen && (
      <div className="card-content">
        <div className="content">
          {props.content}
        </div>
      </div>
      )}
    </div>
  )
}
export default CollapsiblePanel;