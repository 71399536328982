import React, { useState, useEffect } from "react";
import { useParams } from 'react-router';
import Moment from 'react-moment';
import 'moment-timezone';
import {
//  BrowserRouter as Router,
//  Switch,
//  Route,
  Link,
//  NavLink
} from "react-router-dom";
import axios from "axios";
import FixtureList from './Components/FixtureList'
import { findAllInRenderedTree } from "react-dom/test-utils";

function Fixture(props){
  const { fixtureId, leagueName } = useParams();

  const [selectedFixture, setSelectedFixture] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);

  const [activeChildFeedId, setActiveChildFeedId] = useState(3);

  const [selectedFixturePrices, setSelectedFixturePrices] = useState([]);
  const [priceHistory, setPriceHistory] = useState([]);

  const [priceBasedFixtureStatsHistoryRecord, setPriceBasedFixtureStatsHistoryRecord] = useState(null);

  const [excelTimePoints, setExcelTimePoints] = useState([]);

  const [fixedAH, setFixedAH] = useState(null);
  const [fixedTG, setFixedTG] = useState(null);

  var root = this;

  const ngFeeds = [
    {id: 3, name: 'Crown'},
    {id: 47, name: 'NG Pinny'},
    {id: 14, name: 'Vcbet'},
    {id: 8, name: 'Bet365'},
  ]

  var pricesDisabled = false;

  // useEffect(() => {
  //   doGetFixture();
  // }, []) // using [] means this will run exactly once
  
  useEffect(() => {
    doGetFixture(fixtureId, activeChildFeedId);
  }, [fixtureId])

  useEffect(() => {
    if (selectedFixture != null)
    {
      // console.log("selectedFixture")
      // console.log(selectedFixture)
      regenPriceHistory(selectedFixturePrices, fixedAH, fixedTG);
    }
	}, [selectedFixturePrices]); // update if any of these props change

  
  // useEffect(() => {
    
  // }, [priceBasedFixtureStatsHistoryRecord])

  


  function getFixtureTitle(fixture) {
    //console.log(priceBasedFixtureStatsHistoryRecord)
    if (priceBasedFixtureStatsHistoryRecord != null && priceBasedFixtureStatsHistoryRecord.homescore != null)
    {
      return fixture.team1.name + ' '+ priceBasedFixtureStatsHistoryRecord.homescore + " v " + priceBasedFixtureStatsHistoryRecord.guestscore + ' '+ fixture.team2.name; // + " (" + fixture.id + ")";
    }
    
    return fixture.team1.name + " v " + fixture.team2.name; // + " (" + fixture.id + ")";
  }

  function getFixtureLinkToNG(fixture) {
    return "http://data.nowgoal3.com/3in1odds/"+activeChildFeedId+"_"+fixture.id+".html";
  }

  function IsStartTimeInFuturePlusTwoHours(fixture)
  {
    let compareDate = new Date();
    compareDate.setTime(compareDate.getTime() + (2*60*60*1000));
    return (new Date(fixture.startTime) > compareDate);
  }

  // const getPrices = async (matchupId, feedId) => {
  //   console.log('/api/ng/fixture/live?fixtureId=' + encodeURIComponent(matchupId) + '&feedId=' + feedId);
    
  //   const response = await fetch('/api/ng/fixture/live?fixtureId='+encodeURIComponent(matchupId) + '&feedId=' + feedId);
  //   const body = await response.json();
    
  //   //console.log(body)

  //   if (response.status !== 200) {
  //     throw Error(body.message);
  //   }
  //   //console.log(body);

  //   return body; 
  // }

  function setActiveChildFeedIdAndLoad(childFeedId)
  {
    if (childFeedId == activeChildFeedId) {
      console.log('setActiveChildFeedIdAndLoad was called with active id. No action needed.')
      return;
    }

    setSelectedFixturePrices({
      letgoal: [],
      totalscore: []
    });

    setActiveChildFeedId(childFeedId);

    doGetFixture(fixtureId, childFeedId);
  }

  function doGetFixture(fixtureId, feedId)
  {
    axios.get('/api/ng/fixtures?fixtureId=' + fixtureId)
    .then(res => {
      var fixture;
      if (res.data.length === 0 || res.data[0].fixtures.length === 0)
      {
        // fixture not found
        return;

        // allow hacked fixture
        // setSelectedFixture({
        //   "id": fixtureId,
        //   "team1": {
        //       "_id": 0,
        //       "name": "Hack"
        //   },
        //   "team2": {
        //       "_id": 1,
        //       "name": "Hack"
        //   },
        //   "startTime": "2022-01-01T00:00:00.000Z"
        // });
      }
      else
      {
        fixture = res.data[0].fixtures[0];

        setSelectedLeague(res.data[0].league);
        setSelectedFixture(fixture);
        
        console.log(fixture)
      }

      console.log(fixture)

      setFixedAH(null);
      setFixedTG(null);

      if (!pricesDisabled) {
        // getPrices(fixtureId, feedId).then(res => {
        //   //console.log(res);
        //   setSelectedFixturePrices(res);
        // })
        // .catch(err => {
        //   console.log(err)
        // });
        axios.get('/api/ng/fixture/live/excel?fixtureId='+encodeURIComponent(fixtureId) + '&feedId=' + feedId + '&startTime=' + fixture.startTime)
        .then(res => {
          //console.log(res);
          setExcelTimePoints(res.data.excelTimePoints);
          setPriceHistory(res.data.priceHistory);
          setPriceBasedFixtureStatsHistoryRecord(res.data.priceBasedFixtureStatsHistoryRecord);
        })
        .catch(err => {
          console.log(err)
        });
      }
      
    });
  }

  function isEarlyOutput(isearly)
  {
    switch (isearly)
    {
      case 2:
        return 'Run'
      case 0:
        return 'Live'
      case 1:
        return 'Ear'
    }
  }

  function isEarlyOutputClass(isearly)
  {
    switch (isearly)
    {
      case 2:
        return 'back-grey'
      case 0:
        return 'back-red'
      case 1:
        return 'back-green'
    }
  }

  function dateFromHKDateStr(hkDateStr)
  {
    hkDateStr = hkDateStr.replace(' ', 'T');
    hkDateStr = hkDateStr + '.000+08:00';
    return new Date(hkDateStr);
  }

  function regenPriceHistory(fixturePricesGroup, fixedAH, fixedTG)
  {
    // first record will give us score
    
    

    // standard = 1x2
    // letgoal = ah
    // totalscore = tg

    let fixturePricesAH = fixturePricesGroup.letgoal;
    let fixturePricesTG = fixturePricesGroup.totalscore;
    
    if (!fixturePricesAH || !fixturePricesTG) {
      console.log('fixturePricesAH || fixturePricesTG not set');
      return;  
    }

    // combine into a sorted array of AH and TG, just like pinny
    let fixturePrices = [];

    fixturePricesAH.forEach(fixturePrice => {
      fixturePrice.dataType = "spread";

      fixturePrice.handicap = -fixturePrice.goal; // flip handi
      if (fixturePrice.upodds != null) fixturePrice.upodds = fixturePrice.upodds+1;
      if (fixturePrice.downodds != null) fixturePrice.downodds = fixturePrice.downodds+1;

      fixturePrice.marketClosed = (fixturePrice.type == 6 || fixturePrice.type == 7);

      fixturePrice.changedAt = dateFromHKDateStr(fixturePrice.modifytime);
      
      fixturePrices.push(fixturePrice);      
    });

    fixturePricesTG.forEach(fixturePrice => {
      fixturePrice.dataType = "total";
      
      fixturePrice.handicap = fixturePrice.goal;
      if (fixturePrice.upodds != null) fixturePrice.upodds = fixturePrice.upodds+1;
      if (fixturePrice.downodds != null) fixturePrice.downodds = fixturePrice.downodds+1;

      fixturePrice.marketClosed = (fixturePrice.type == 6 || fixturePrice.type == 7);

      fixturePrice.changedAt = dateFromHKDateStr(fixturePrice.modifytime);

      fixturePrices.push(fixturePrice);      
    });


    if (fixturePrices.length > 0)
    {
      setPriceBasedFixtureStatsHistoryRecord(fixturePrices[0]);
    }
    else
    {
      setPriceBasedFixtureStatsHistoryRecord(null);
    }


    // let fixturePrices = fixturePricesAH.concat(fixturePricesTG)

    fixturePrices.sort((a, b) => {
        //return (a.changedAt).localeCompare((b.changedAt))
        if (a.changedAt < b.changedAt) {
          return -1;
        }
        if (a.changedAt > b.changedAt) {
          return 1;
        }
        return 0;
    });

    setPriceHistory([]);


    let newPH = [];

    let mainLineAHFixturePriceBlocks = [];
    let mainLineTGFixturePriceBlocks = [];
    let uniqueChangedAts = [];
    let currentChangedAt = null;

  // console.log('fixturePrices for lastseen diag')
  // console.log(fixturePrices);
    // convert to our models, and make a note of all the unique changed at dates
    
    let prevFixturePriceBlock = null;
    
    fixturePrices.forEach(fixturePriceBlock => {
      if (currentChangedAt == null || (currentChangedAt.getTime() != fixturePriceBlock.changedAt.getTime())) // note: comparing date to date did not work
      {
        currentChangedAt = fixturePriceBlock.changedAt;
        uniqueChangedAts.push(currentChangedAt);
        //console.log(currentChangedAt + ' VS ' + fixturePriceBlock.changedAt);
      }

      // let fixedAH = this.state.fixedAH;
      // let fixedTG = this.state.fixedTG; // should use 2.75
      let fixedH = null;

      let decimalPriceGroups = [];

      switch (fixturePriceBlock.dataType)
      {
        case "spread":
          if (fixedAH != null)
          {
            fixedH = fixedAH;
          }
          break;
        case "total":
          if (fixedTG != null)
          {
            fixedH = fixedTG;
          }
          break;
        default:
          console.log("unexpected type: " + fixturePriceBlock.dataType)
      }

      decimalPriceGroups = convertToDecimalPriceGroups(fixturePriceBlock, fixedH);
      
      // reorder prices (may want to do it differently for TGs)
      decimalPriceGroups.sort((a, b) => {
              
        if (a.pDist > b.pDist) {
          return 1;
        } else if (a.pDist < b.pDist) { 
            return -1;
        }

        // else go to the 2nd item
        if (a.hDist < b.hDist) { 
            return -1;
        } else if (a.hDist > b.hDist) {
            return 1
        } else { // nothing to split them
            return 0;
        }
      });

      if (decimalPriceGroups.length > 0) { // will be 0 if user has chosen a fixed handicap that wasn't found, in which case we ignore/do nothing

        let decimalPriceGroup = decimalPriceGroups[0];
        
        // add changed at property
        decimalPriceGroup.changedAt = currentChangedAt;
        
        // add last seen at property (double check which one we're using here)
        // TODO: NowGoal specific, as we don't have a last seen at, use changed at instead - this may be an error! (was = fpb.lastSeenAt)
        //decimalPriceGroup.lastSeenAt = fixturePriceBlock.lastSeenAt;

        decimalPriceGroup.lastSeenAt = currentChangedAt;

        switch (fixturePriceBlock.dataType)
        {
          case "spread":
            mainLineAHFixturePriceBlocks.push(decimalPriceGroup);
            break;
          case "total":
            mainLineTGFixturePriceBlocks.push(decimalPriceGroup);
            break;
          default:
            console.log("unexpected type: " + fixturePriceBlock.dataType)
        }
      }

      prevFixturePriceBlock = fixturePriceBlock;
    });

    // console.log("mainLineAHFixturePriceBlocks")
    // console.log(mainLineAHFixturePriceBlocks)

    // let currentRow = null;
    let AHcursor = 0;
    let TGcursor = 0;
    let currentAHMain = null;
    let currentTGMain = null;

    // the last objs are used to work out differences
    let lastAHMain = null;
    let lastTGMain = null;

    uniqueChangedAts.forEach(changedAt => {
      
      // seek up our AH models until we get to the most recent one at the time of this changed at
      while (AHcursor < mainLineAHFixturePriceBlocks.length)
      {
        // keep replacing until we find the latest AH up to that time
        //console.log(mainLineAHFixturePriceBlocks[AHcursor].changedAt + " <= " + changedAt)
        
        if (mainLineAHFixturePriceBlocks[AHcursor].changedAt <= changedAt)
        {
          currentAHMain = mainLineAHFixturePriceBlocks[AHcursor];
          // console.log(currentAHMain)

          // move back so that each iteration we only loop from the last spot, not through the whole array from top to bottom
          AHcursor++;
        }
        else
        {
          break;
        }
      }

      // seek up our AH models until we get to the most recent one at the time of this changed at
      while (TGcursor < mainLineTGFixturePriceBlocks.length)
      {
        // keep replacing until we find the latest TG up to that time
        if (mainLineTGFixturePriceBlocks[TGcursor].changedAt <= changedAt)
        {
          currentTGMain = mainLineTGFixturePriceBlocks[TGcursor];
          
          // move back so that each iteration we only loop from the last spot, not through the whole array from top to bottom
          TGcursor++;
        }
        else
        {
          break;
        }
      }
      
      if (currentAHMain != null)
      {
        addChangeDirectionToCurrentLine(currentAHMain, lastAHMain);
      }
      if (currentTGMain != null)
      {
        addChangeDirectionToCurrentLine(currentTGMain, lastTGMain);
      }

      if (currentAHMain != null && currentTGMain != null) { // this condition will hopefully mean that if only one line or the other is foound, we move forward/ignore it. Fix for first prices having only AH and no TG
        newPH.push({
          AH: currentAHMain,
          TG: currentTGMain,
          lastSeenAt: currentAHMain.lastSeenAt, // just using AH as they come in together
          AHLastSeenAt: currentAHMain.lastSeenAt, // just using AH as they come in together
          TGLastSeenAt: currentTGMain.lastSeenAt, // just using AH as they come in together
          changedAt: changedAt   
        })
  
        lastAHMain = currentAHMain;
        lastTGMain = currentTGMain;
      }
    });
    
    //console.log(newPH)
    setPriceHistory(newPH);

    // regen excel
    regenExcelTimePoints(selectedFixture.startTime, newPH);
  }

  function regenExcelTimePoints(kickoff, priceHistory) {
    kickoff = new Date(kickoff);
    
    let dayOP = new Date();

    // get the first data point for AH/TG
    if (priceHistory.length > 0)
    {
      dayOP = new Date(priceHistory[0].changedAt);
    }

    // let day6 = new Date(kickoff.getTime());
    // day6.setHours(day6.getHours() - (24*6));
    // day6.setHours(9); //set to 9am
    // day6.setMinutes(0);

    // let day5 = new Date(kickoff.getTime());
    // day5.setHours(day5.getHours() - (24*5));
    // day5.setHours(9); //set to 9am
    // day5.setMinutes(0);

    // let day4 = new Date(kickoff.getTime());
    // day4.setHours(day4.getHours() - (24*4));
    // day4.setHours(9); //set to 9am
    // day4.setMinutes(0);

    // let day3 = new Date(kickoff.getTime());
    // day3.setHours(day3.getHours() - (24*3));
    // day3.setHours(9); //set to 9am
    // day3.setMinutes(0);

    // let day2 = new Date(kickoff.getTime());
    // day2.setHours(day2.getHours() - (24*2));
    // day2.setHours(9); //set to 9am
    // day2.setMinutes(0);
    
    let day1 = new Date(kickoff.getTime());
    day1.setHours(day1.getHours() - (24*1));
    day1.setHours(9, 0, 0); //set to 9am
    
    let sameDay9am = new Date(kickoff.getTime());
    sameDay9am.setHours(9, 0, 0);
    let label9am = "9am";

    let dayBefore11pm = new Date(kickoff.getTime());
    dayBefore11pm.setHours(dayBefore11pm.getHours() - 24);
    dayBefore11pm.setHours(23, 0, 0); //set to 11pm
    let dayBeforeLabel11pm = "11pm day before";

    let dayBefore9am = new Date(kickoff.getTime());
    dayBefore9am.setHours(dayBefore9am.getHours() - 24);
    dayBefore9am.setHours(9, 0, 0); //set to 9am
    let dayBeforeLabel9am = "9am day before";

    // hours are 0 - 23
    //if (sameDay9am.getHours() <= 9)
    if (kickoff.getHours() <= 9)
    {
      // if it's very early we simply look at market 9hrs earlier
      ///am.setHours(am.getHours() - 9);

      sameDay9am.setHours(-10);
      sameDay9am.setHours(9, 0, 0);

      label9am = "9am day before";
      dayBeforeLabel11pm = "11pm TWO days before";
      dayBeforeLabel9am = "9am TWO days before";

      // move all other days back by 1 day
      day1.setHours(day1.getHours()-24);
      dayBefore11pm.setHours(dayBefore11pm.getHours()-24);
      dayBefore9am.setHours(dayBefore9am.getHours()-24);
    }

    let fudge = -1; // fudge of 1hr, could be bst quickfix
    
    let hr1BeforeKickoff = new Date(kickoff.getTime());
    hr1BeforeKickoff.setHours(kickoff.getHours() - 1 + fudge); // fudge

    let hr3BeforeKickoff = new Date(kickoff.getTime());
    hr3BeforeKickoff.setHours(kickoff.getHours() - 3 + fudge); // fudge

    let hr6BeforeKickoff = new Date(kickoff.getTime());
    hr6BeforeKickoff.setHours(kickoff.getHours() - 6 + fudge); // fudge

    
    
    // let alt = new Date(kickoff.getTime());
    // alt.setMinutes(alt.getMinutes() + 3);

    // let min10AfterKickoff = new Date(kickoff.getTime());
    // min10AfterKickoff.setMinutes(min10AfterKickoff.getMinutes() + 10);

    let now = new Date();
    let dateToUseForKO = kickoff;

    // requirmeent for KO to be filled with latest price if we have yet to reach kickoff datetime
    if (now < dateToUseForKO)
    {
      dateToUseForKO = now;
    }

    // let excelTimePoints = [ 
    //   {label: "OP", stopPoint: dayOP, dontCopy: false},
    //   // {label: "6", stopPoint: day6, dontCopy: false},
    //   // {label: "5", stopPoint: day5, dontCopy: false},
    //   {dataPoint:null, label: "4", stopPoint: day4, dontCopy: false},
    //   {dataPoint:null, label: "3", stopPoint: day3, dontCopy: false},
    //   {dataPoint:null, label: "2", stopPoint: day2, dontCopy: false},
    //   {dataPoint:null, label: "1", stopPoint: day1, dontCopy: false},
    //   {dataPoint:null, label: label9am, stopPoint: am, dontCopy: false},
    //   {dataPoint:null, label: "1hr", stopPoint: hr1BeforeKickoff, dontCopy: false},
    //   {dataPoint:null, label: "ko", stopPoint: null, dontCopy: false, isKickoffValue: true, fixedMin: 0, lastAvailable: true},
    //   {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 1, minSpaceFromPreviousMin: 0, autoLabel:true},
    //   {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 3, minSpaceFromPreviousMin: 2, autoLabel:true},
    //   {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 5, minSpaceFromPreviousMin: 2, autoLabel:true},
    //   {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 10, minSpaceFromPreviousMin: 0, autoLabel:true},
    //   {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 12, minSpaceFromPreviousMin: 1, autoLabel:true},
    // ];

    let excelTimePoints = [ 
      {label: "OP", stopPoint: dayOP, dontCopy: false},
      // {label: "6", stopPoint: day6, dontCopy: false},
      // {label: "5", stopPoint: day5, dontCopy: false},
      //{dataPoint:null, label: "4", stopPoint: day4, dontCopy: false},
      //{dataPoint:null, label: "3", stopPoint: day3, dontCopy: false},
      //{dataPoint:null, label: "2", stopPoint: day2, dontCopy: false},
      //{dataPoint:null, label: "1", stopPoint: day1, dontCopy: false},

      {dataPoint:null, label: dayBeforeLabel9am, stopPoint: dayBefore9am, dontCopy: false},
      {dataPoint:null, label: dayBeforeLabel11pm, stopPoint: dayBefore11pm, dontCopy: false},
      {dataPoint:null, label: label9am, stopPoint: sameDay9am, dontCopy: false},
      
      {dataPoint:null, label: "6hr", stopPoint: hr6BeforeKickoff, dontCopy: false},
      {dataPoint:null, label: "3hr", stopPoint: hr3BeforeKickoff, dontCopy: false},
      {dataPoint:null, label: "1hr", stopPoint: hr1BeforeKickoff, dontCopy: false},
      {dataPoint:null, label: "ko", stopPoint: null, dontCopy: false, isKickoffValue: true, fixedMin: 0, lastAvailable: true},
      {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 1, minSpaceFromPreviousMin: 0, autoLabel:true},
      {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 3, minSpaceFromPreviousMin: 2, autoLabel:true},
      {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 5, minSpaceFromPreviousMin: 2, autoLabel:true},
      {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 10, minSpaceFromPreviousMin: 0, autoLabel:true},
      {dataPoint:null, label: null, stopPoint: null, dontCopy: false, isearly:2, fixedMin: 12, minSpaceFromPreviousMin: 1, autoLabel:true},
    ];

    // console.log('debug newPH');
    // console.log(JSON.stringify(excelTimePoints));
    
    var previousExcelTimePoint;
    // now associate the correct data history point with each timePoint
    excelTimePoints.forEach(excelTimePoint => {
      let fixedMinMode = (excelTimePoint.stopPoint == null);
      
      let mostRecentValidDataPoint = null;

      if (excelTimePoint.isKickoffValue == true && excelTimePoint.lastAvailable == true)
      {
        
        // special case, get last live price
        priceHistory.filter(phi => 
          phi.AH.marketClosed === false && phi.TG.marketClosed === false
          && (

            (phi.AH.isearly == 0 && phi.TG.isearly == 0)
            ||
            (phi.AH.isearly == 1 && phi.TG.isearly == 1)

          )
          ).forEach(priceHistoryItem => {
          // only use times if they are the same
          if (priceHistoryItem.AH.happentime == priceHistoryItem.TG.happentime) {
            //console.log('hello' + parseInt(priceHistoryItem.happentime))
            mostRecentValidDataPoint = priceHistoryItem;
            
          }
        });

        excelTimePoint.dataPoint = mostRecentValidDataPoint;
      }
      else if (fixedMinMode) {
        if (previousExcelTimePoint != null && previousExcelTimePoint.fixedMin != null) {
          // ensure that we respect minSpace
          excelTimePoint.fixedMin = Math.max(excelTimePoint.fixedMin, previousExcelTimePoint.fixedMin + excelTimePoint.minSpaceFromPreviousMin);
        }

        var validPhis = priceHistory.filter(phi =>
          phi.AH.marketClosed === false && phi.TG.marketClosed === false &&
          phi.AH.isearly == excelTimePoint.isearly && phi.TG.isearly == excelTimePoint.isearly
          && phi.AH.happentime != 'HT'
          && phi.TG.happentime != 'HT'
          && phi.AH.happentime !== null && phi.TG.happentime !== null
          && parseInt(phi.AH.happentime) >= excelTimePoint.fixedMin // only need AH as AH==TG is checked later
          && phi.AH.happentime == phi.TG.happentime // only use times if they are the same
          );


        if (validPhis.length > 0)
        {
          mostRecentValidDataPoint = validPhis[0];

          //console.log(parseInt(mostRecentValidDataPoint.AH.happentime))
          excelTimePoint.fixedMin = parseInt(mostRecentValidDataPoint.AH.happentime);
          excelTimePoint.label = excelTimePoint.fixedMin;

          excelTimePoint.dataPoint = mostRecentValidDataPoint;
        }
      }
      else
      {
        let currentStopPoint = excelTimePoint.stopPoint;

          // make sure if we're viewing before ko that we don't fill grid with data we don't need
        if (!(new Date(excelTimePoint.stopPoint) > now))
        {
          //console.log("priceHistory: " + priceHistory)
          priceHistory.forEach(priceHistoryItem => {
            // an inefficient approach as it'll finish the loop (really want to "break" but this will have same effect)
              // try
              // {
              //   console.log(priceHistoryItem.happentime)
              //   if (excelTimePoint.fixedMin <= parseInt(priceHistoryItem.happentime)) {
              //     mostRecentValidDataPoint = priceHistoryItem;
              //   }
              // }
              // finally
              // {
              //   console.log('hit an invalid fixedMin, maybe halftime or no data')
              // }
              //console.log(priceHistoryItem.changedAt + " <= " + currentStopPoint)
              if (new Date(priceHistoryItem.changedAt) <= currentStopPoint)
              {
                mostRecentValidDataPoint = priceHistoryItem;  
              }
          });
        }

        excelTimePoint.dataPoint = mostRecentValidDataPoint;
      }

      previousExcelTimePoint = excelTimePoint;
    });
    
    

    setExcelTimePoints(excelTimePoints);
  }
  
  function addChangeDirectionToCurrentLine(currentLine, lastLine)
  {
    if (lastLine == null)
    {
      currentLine.handicapDirection = 0;
      currentLine.price1Direction = 0;
      currentLine.price2Direction = 0;
      return;
    }

    if (currentLine.handicap > lastLine.handicap)
    {
      currentLine.handicapDirection = 1;
    }
    else if (currentLine.handicap < lastLine.handicap)
    {
      currentLine.handicapDirection = -1;
    }
    else
    {
      currentLine.handicapDirection = 0;
    }

    if (currentLine.price1 > lastLine.price1)
    {
      currentLine.price1Direction = 1;
    }
    else if (currentLine.price1 < lastLine.price1)
    {
      currentLine.price1Direction = -1;
    }
    else
    {
      currentLine.price1Direction = 0;
    }

    if (currentLine.price2 > lastLine.price2)
    {
      currentLine.price2Direction = 1;
    }
    else if (currentLine.price2 < lastLine.price2)
    {
      currentLine.price2Direction = -1;
    }
    else
    {
      currentLine.price2Direction = 0;
    }
  }

  function toDecimalFromAmerican(americanOdds)
  {
    if (americanOdds == null)
    {
        return americanOdds;
    }

    if (americanOdds > 0)
    {
        return 1 + (americanOdds / 100);
    }
    else
    {
        return 1 - (100 / americanOdds);
    }
  }

  function distanceFromEvens(price1, price2)
  {
    // this.DistanceFromEvens = Math.Round((Math.Abs(1 - Math.Abs(this.Price1[0].SystemPrice))) + (Math.Abs(1 - Math.Abs(this.Price2[0].SystemPrice))), 3);
    return (Math.abs(1 - Math.abs(price1))) + (Math.abs(1 - Math.abs(price2)));
  }

  function handicapDistanceFromZero(handicap)
  {
    return Math.abs(0 - handicap);
  }

  //function convertToDecimalPriceGroups(priceGroups, fixedHandicap)
  function convertToDecimalPriceGroups(fixturePriceBlock, fixedHandicap)
  {
    // input is priceGroups e.g. 
    /*
      [
      {"points":"0.75","prices":[
        {"designation":"home","price":-114,"points":"0.75"},
        {"designation":"away","price":105,"points":"-0.75"}
      ]},
      {"points":"-0.25","prices":
      [{"designation":"home","price":274,"points":"-0.25"},
      {"designation":"away","price":-320,"points":"0.25"}
      ]},
    */

    let decimalPriceGroup = [];

    // // convert price groups to a new decimal price group
    // priceGroups.filter(pg => (fixedHandicap == null || pg.points == fixedHandicap)).forEach(priceGroup => {
    //   let price1 = toDecimalFromAmerican(priceGroup.prices[0].price);
    //   let price2 = toDecimalFromAmerican(priceGroup.prices[1].price);
    //   let handicap = parseFloat(priceGroup.points);
    //   //console.log("Z" + handicap)

    //   decimalPriceGroup.push({
    //     handicap: handicap,
    //     price1: price1,
    //     price2: price2,
    //     hDist: handicapDistanceFromZero(handicap),
    //     pDist: distanceFromEvens(price1, price2),
    //   });
    // });
    
    decimalPriceGroup.push({
      happentime: (fixturePriceBlock.happentime ? fixturePriceBlock.happentime.replace('中场', 'HT') : null), // eg. null,
      homescore: fixturePriceBlock.homescore, // eg. null,
      guestscore: fixturePriceBlock.guestscore, // eg. null,
      upodds: fixturePriceBlock.upodds, // eg. 0.8700000047683716,
      goal: fixturePriceBlock.goal, // eg. 2.5,
      downodds: fixturePriceBlock.downodds, // eg. 1.0299999713897705,
      modifytime: fixturePriceBlock.modifytime, // eg. "2021-08-29 19:52:45",
      isearly: fixturePriceBlock.isearly, // eg. 0,
      type: fixturePriceBlock.type, // eg. null,
      homered: fixturePriceBlock.homered, // eg. 0,
      guestred: fixturePriceBlock.guestred, // eg. 0,
      scorecolor: fixturePriceBlock.scorecolor, // eg. "",
      goalcolor: fixturePriceBlock.goalcolor, // eg. "",
      oddscolor: fixturePriceBlock.oddscolor, // eg. "green",
      oddscolor2: fixturePriceBlock.oddscolor2, // eg. "red"
      
      marketClosed: fixturePriceBlock.marketClosed,
      handicap: fixturePriceBlock.handicap,
      price1: fixturePriceBlock.upodds,
      price2: fixturePriceBlock.downodds,
      hDist: handicapDistanceFromZero(fixturePriceBlock.handicap),
      pDist: distanceFromEvens(fixturePriceBlock.price1, fixturePriceBlock.price2),
    });


    return decimalPriceGroup;
  }

  function handleHandicapClick(newAH, newTG) {

    let updateRequired = false;

    if (newAH != fixedAH)
    {
      updateRequired = true;

      setFixedAH(newAH);
    }

    if (newTG != fixedTG)
    {
      updateRequired = true;

      setFixedTG(newTG);
    }

    if (updateRequired)
    {
      regenPriceHistory(selectedFixturePrices, newAH, newTG);
    }
  }

  function fixToKOHandicaps(){
    // find ko handicap
    excelTimePoints.filter(e => e.label === "ko").forEach(excelTimePoint => {
      //console.log(excelTimePoint)
      handleHandicapClick(excelTimePoint.dataPoint.AH.handicap, excelTimePoint.dataPoint.TG.handicap)
    });
  }

  function copyMarketOverviewToClipboard() {
    let marketOverviewTableRows = document.querySelectorAll('#marketOverview tr');

    let tableForClipboard = [];

    marketOverviewTableRows.forEach(row => {
      let clipRow = [];

      let cells = row.querySelectorAll('th, td');

      let skipRow = false;
      cells.forEach(cell => {
        let cellText = cell.innerText;
        if (cellText === 'MKT Sup' || cellText === 'MKT TG')												
        {
          skipRow = true; // note, it'll still loop through rest of row, but skpRow won't be reset
        }

        if (cell.className != "dontcopy") {
          clipRow.push(cellText);
        }
        else
        {
          // no cell will be pushed
        }
        
      });

      if (!skipRow) {
        tableForClipboard.push(clipRow);
      }
    });

    

    copy2DToClipboard(tableForClipboard);
  }

  function copy2DToClipboard(array) {
    var csv = '', row, cell;
    for (row = 0; row < array.length; row++) {
      for (cell = 0; cell < array[row].length; cell++) {
        csv += (array[row][cell]+'').replace(/[\n\t]+/g, ' ');
        if (cell+1 < array[row].length) csv += '\t';
      }
      if (row+1 < array.length) csv += '\n';
    }

    // for debug as clipboard did not update for user W
    //setDebugClipboardOutput: array;

    copyTextToClipboard(csv);
  }
  
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }


  
  // var oddsLiquidity = 100; //props.oddsLiquidity;

  // const [currentPrice, setCurrentPrice] 	= useState(oddsLiquidity.odds);
  // const [currentPriceClass, setCurrentPriceClass] 	= useState('');

  // useEffect(() => {
		
	// 	if (oddsLiquidity.odds === currentPrice){
  //    return;
  //   }

  //   if (currentPrice < oddsLiquidity.odds)
  //   {
  //     setCurrentPriceClass("price-up");
  //   }
  //   else
  //   {
  //     setCurrentPriceClass("price-down");
  //   }
    
  //   setCurrentPrice(oddsLiquidity.odds);

	// }, [oddsLiquidity, currentPrice]); // update if either of these props change

  // return (
  //   <div className="backPriceBox">
  //     <div className={'price ' + currentPriceClass}>{parseFloat(oddsLiquidity.odds).toFixed(2)}</div>
  //     <div className="liquidty">&pound;{parseFloat(oddsLiquidity.liquidity).toFixed(2)}</div>
  //   </div>
  // )

  return (
          <div>
            {selectedFixture !== null && (
              <div>
                <div>
                  <div className="columns">
                    <div className="column">





{/* 
<div className="tabs is-boxed mt-3">
  <ul>
    <li className="is-active">
      <a>
        <span className="icon is-small"><i className="fas fa-eye" aria-hidden="true"></i></span>
        <span>Overview</span>
      </a>
    </li>
    <li>
      <a>
        <span className="icon is-small"><i className="fas fa-table" aria-hidden="true"></i></span>
        <span>Market</span>
      </a>
    </li>
  </ul>
</div> */}


                      <div className="header">
                        <Link to={'/ng#'+leagueName}>{leagueName}</Link>
                        <h1 className="title">{getFixtureTitle(selectedFixture)}</h1>
                        {/* <Moment date={selectedFixture.startTime} format="dd hh:mm:ss" fromNowDuring /> @  */}
                        <h2 className="subtitle"><Moment format="ddd DD-MM HH:mm" date={selectedFixture.startTime} /> - <span>Now Goal:&nbsp;</span>
                          <a target="_blank" href={getFixtureLinkToNG(selectedFixture)}>{selectedFixture.id}</a>
                        </h2>
                      </div>
                      <div className="buttons has-addons">
                        {ngFeeds.map(ngFeed => 
                          <button key={ngFeed.id} className={'button is-small ' + (activeChildFeedId===ngFeed.id ? 'is-primary' : 'is-link') } onClick={() => setActiveChildFeedIdAndLoad(ngFeed.id)} >{ngFeed.name}</button>  
                        )}
                        
                        {/* <li id="comBtn_24"><a href="/3in1odds/2027975/?cid=24">12BET</a></li>
                        <li id="comBtn_31"><a href="/3in1odds/2027975/?cid=31">Sbobet</a></li>
                        <li id="comBtn_1"><a href="/3in1odds/2027975/?cid=1">Macauslot</a></li>
                        <li id="comBtn_4"><a href="/3in1odds/2027975/?cid=4">Ladbrokes</a></li>
                        <li id="comBtn_8"><a href="/3in1odds/2027975/?cid=8">Bet365</a></li>
                        <li id="comBtn_12"><a href="/3in1odds/2027975/?cid=12">Easybet</a></li>
                        <li id="comBtn_14"><a href="/3in1odds/2027975/?cid=14">Vcbet</a></li>
                        <li id="comBtn_17"><a href="/3in1odds/2027975/?cid=17">M88</a></li>
                        <li id="comBtn_23"><a href="/3in1odds/2027975/?cid=23">188bet</a></li>
                        <li id="comBtn_9"><a href="/3in1odds/2027975/?cid=9">Willhill</a></li>
                        <li id="comBtn_7"><a href="/3in1odds/2027975/?cid=7">SNAI</a></li>
                        <li id="comBtn_19"><a href="/3in1odds/2027975/?cid=19">Interwetten</a></li>
                        <li id="comBtn_42"><a href="/3in1odds/2027975/?cid=42">18Bet</a></li> */}
                      </div>
                    </div>
                    <div className="column">
                      <FixtureList round={selectedFixture.roundNum} selectedLeague={selectedLeague} activeFixtureId={selectedFixture.id} />
                    </div>
                  </div>
                  
                  <div><h1>Market Overview</h1></div>

                  
                  <div className="buttons are-small">
                      <button className="button is-link" onClick={() => copyMarketOverviewToClipboard()} >
                        <span className="icon">
                          <i className="fas fa-clipboard"></i>
                        </span>
                        <span>Copy</span>
                      </button>
                  </div>
                  <div>
                  <table className="table is-hoverable market-overview" id="marketOverview">
                      <thead>
                      {/*                         
                        <tr >
                          <th>cut off at</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{(
                              <div><Moment format="Do HH:mm:ss" date={excelTimePoint.stopPoint} /></div>
                            )}
                            </td>    
                          )}
                        </tr> */}
                        
                        <tr>
                          <th>last seen at AH</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint !== null && excelTimePoint.dataPoint !== null && (
                              <div>
                              <div><Moment format="DD-MM-yy HH:mm:ss" date={excelTimePoint.dataPoint.lastSeenAt} /></div>
                              </div>

                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>last seen at TG</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && (
                              <div>
                              <div><Moment format="DD-MM-yy HH:mm:ss" date={excelTimePoint.dataPoint.TGLastSeenAt} /></div>
                              </div>

                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th>since</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && (
                              <div><Moment format="DD-MM-yy HH:mm:ss" date={excelTimePoint.dataPoint.changedAt} /></div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th></th>
                          {excelTimePoints.map(excelTimePoint => 
                            <th className={excelTimePoint.dontCopy?'dontcopy':''} tooltip={excelTimePoint.stopPoint}>
                              <div>
                                {excelTimePoint.label} 
                                {excelTimePoint !== excelTimePoint && excelTimePoint.fixedMin!==null && (<div>{excelTimePoint.dataPoint.AH.happentime}</div>)}
                              </div>
                              
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>HC</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.AH !== null && (
                              <div>
                                <div className="handicap-notoggle">{excelTimePoint.dataPoint.AH.handicap.toFixed(2)}</div>
                              </div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th>home</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.AH !== null && (
                              <div>{excelTimePoint.dataPoint.AH.price1.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>away</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.AH !== null && (
                              <div>{excelTimePoint.dataPoint.AH.price2.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>TG</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.TG !== null && (
                              <div>
                                <div className="handicap-notoggle">{excelTimePoint.dataPoint.TG.handicap.toFixed(2)}</div>
                              </div>
                            )}
                            </td>    
                          )}
                        </tr>
                        <tr>
                          <th>O</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.TG !== null && (
                              <div>{excelTimePoint.dataPoint.TG.price1.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>U</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td>{excelTimePoint.dataPoint !== null && excelTimePoint.dataPoint.TG !== null && (
                              <div>{excelTimePoint.dataPoint.TG.price2.toFixed(2)}</div>
                            )}
                            </td>
                          )}
                        </tr>
                        <tr>
                          <th>MKT Sup</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td></td>    
                          )}
                        </tr>
                        <tr>
                          <th>MKT TG</th>
                          {excelTimePoints.map(excelTimePoint => 
                            <td></td>    
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div><h1>Price History</h1></div>
                  <div>
                    <table className="table is-hoverable is-striped market-history">
                      <thead>
                        <tr>
                          {/* <th>lastSeenAt</th> */}
                          <th colSpan="2">changed</th>
                          <th>Score</th>
                          <th>State AH</th>
                          <th>Time AH</th>
                          <th>AH</th>
                          <th>Home</th>
                          <th>Away</th>
                          <th>State TG</th>
                          <th>Time TG</th>
                          <th>TG</th>
                          <th>Overs</th>
                          <th>Unders</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceHistory.map(phRow => 
                          <tr>
                            {/* <td><Moment format="DD-MM HH:mm:ss" date={phRow.lastSeenAt} /></td> */}
                            <td><Moment format="ddd Do" date={phRow.changedAt} /></td>
                            <td><Moment format="HH:mm:ss" date={phRow.changedAt} /></td>
                            
                            <td>{phRow.AH !== null && (
                              <div>
                                <div>
                                  {((phRow.AH.homescore == phRow.TG.homescore)&&(phRow.AH.guestscore == phRow.TG.guestscore)) ? (
                                    <div>{phRow.AH.homescore} - {phRow.AH.guestscore}</div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                                <div>
                                {/* {((phRow.AH.homered == phRow.TG.homered)&&(phRow.AH.guestred == phRow.TG.guestred)) ? (
                                  <div>
                                    {phRow.AH.homered != null && phRow.AH.guestred != null && (
                                        <span class="red-card">{phRow.AH.homered}</span> - <span class="red-card">{phRow.AH.guestred}</span>
                                    )}
                                  </div>
                                ) : (
                                  <div></div>
                                )} */}
                                </div>
                              </div>
                            )}</td>

                            <td>
                              {phRow.AH !== null && (
                                <div className={isEarlyOutputClass(phRow.AH.isearly)}>{isEarlyOutput(phRow.AH.isearly)}</div> 
                              )}
                            </td>

                            <td>{phRow.AH !== null && (
                              <div>{phRow.AH.happentime}</div>
                            )}</td>

                            <td>{phRow.AH !== null && (
                              <div>
                                {(phRow.AH.marketClosed ? (
                                  <div>Closed</div>
                                ) : (
                                  <div className="handicap-notoggle">{phRow.AH.handicap.toFixed(2)}</div>
                                ))}
                              </div>
                            )}</td>
                            <td>{phRow.AH !== null && (
                              <div>
                                {(phRow.AH.marketClosed ? (
                                  <div> </div>
                                ) : (
                                  <div className={"price change-direction-"+phRow.AH.price1Direction}>{phRow.AH.price1.toFixed(2)}</div>
                                ))}
                              </div>
                            )}</td>
                            <td>{phRow.AH !== null && (
                              <div>
                                {(phRow.AH.marketClosed ? (
                                  <div> </div>
                                ) : (
                                  <div className={"price change-direction-"+phRow.AH.price2Direction}>{phRow.AH.price2.toFixed(2)}</div>
                                ))}
                              </div>
                            )}</td>

                            <td>
                              {phRow.TG !== null && (
                                <div className={isEarlyOutputClass(phRow.TG.isearly)}>{isEarlyOutput(phRow.TG.isearly)}</div> 
                              )}
                            </td>
                            
                            <td>{phRow.TG !== null && (
                              <div>{phRow.TG.happentime}</div>
                            )}</td>

                            <td>{phRow.TG !== null && (
                              <div>
                                {(phRow.TG.marketClosed ? (
                                  <div>Closed</div>
                                ) : (
                                  <div className="handicap-notoggle">{phRow.TG.handicap.toFixed(2)}</div>
                                ))}
                              </div>
                            )}</td>
                            <td>{phRow.TG !== null && (
                              <div>
                                {(phRow.TG.marketClosed ? (
                                  <div></div>
                                ) : (
                                  <div className={"price change-direction-"+phRow.TG.price1Direction}>{phRow.TG.price1.toFixed(2)}</div>
                                ))}
                              </div>
                            )}</td>
                            <td>{phRow.TG !== null && (
                              <div>
                                {(phRow.TG.marketClosed ? (
                                  <div></div>
                                ) : (
                                  <div className={"price change-direction-"+phRow.TG.price2Direction}>{phRow.TG.price2.toFixed(2)}</div>
                                ))}
                              </div>
                            )}</td>
                            {/* <td>{phRow.TG.handicap.toFixed(2)}</td>
                            <td>{phRow.TG.price1.toFixed(2)}</td>
                            <td>{phRow.TG.price2.toFixed(2)}</td> */}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
    
  )
}
export default Fixture;